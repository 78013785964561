import AddTasksPanel from "./AddTasksPanel";
import {Accordion} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {ProcessTeam, ProcessTool} from "../../interfaces";
import {getTeams, getTools} from "../../api";
import {useRecoilState, useRecoilValue} from "recoil";
import {organizationUuidState, processActiveTaskUuid, processTasksState} from "../../store";
import {TaskListRow} from "./TaskListRow";

interface TaskListProps {
    editMode: boolean;
    templateUuid: string;
    wideMode: boolean;
    organizationUuid?: string | null;
}

export default function TaskList(props: TaskListProps) {
    const [teams, setTeams] = useState<ProcessTeam[]>([])
    const [tools, setTools] = useState<ProcessTool[]>([])
    const organizationUuid = useRecoilValue(organizationUuidState)
    const processTasks = useRecoilValue(processTasksState)
    const [activeKey, setActiveKey] = useRecoilState(processActiveTaskUuid)

    const toggleAccordion = useCallback((key: string) => {
        setActiveKey(activeKey === key ? null : key);
    }, [activeKey]);

    useEffect(() => {
        if (props.templateUuid) {
            Promise.all([
                getTeams().then(data => setTeams(data)),
                getTools().then(data => setTools(data)),
            ]).catch(error => console.error(error))
        }
    }, [props.templateUuid, organizationUuid]);

    return (
        <>
        <Accordion flush activeKey={activeKey} onSelect={(key) => setActiveKey(key as string)}>
            {processTasks.map(task => (
                <TaskListRow key={task.uuid}
                             task={task}
                             teams={teams}
                             tools={tools}
                             toggleAccordion={toggleAccordion}
                             editMode={props.editMode}></TaskListRow>
            ))}
        </Accordion>
        {props.editMode && <AddTasksPanel
            teams={teams}
            tools={tools}
            templateUuid={props.templateUuid}/>}
        </>)
}