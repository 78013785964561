import {useRouteError} from "react-router-dom";
import React from "react";
import {errorState} from "../store";
import {useRecoilValue} from "recoil";
import {CenterContainer} from "../components/CenterContainer";

export default function ErrorView() {
    const routeError: any = useRouteError();
    const appError: any = useRecoilValue(errorState)
    const error = appError || routeError;
    return (
        <CenterContainer>
            <div>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error?.statusText || error?.message}</i>
                </p>
            </div>
        </CenterContainer>
    );
}