import React, {useState} from "react";
import {DndContext, DragEndEvent, DragOverEvent, DragStartEvent, useSensor, useSensors,} from "@dnd-kit/core";
import {WizardArrangeTasks, WizardProcess} from "../../../interfaces";
import {arrayMove, insertAtIndex, removeAtIndex} from "./utils";
import {useRecoilState} from "recoil";
import {MouseSensor} from "./MouseSensor";
import DroppableButtonContainer from "./DroppableButtonContainer";
import DroppableCardContainer from "./DroppableCardContainer";
import DraggableOverlay from "./DraggableOverlay";
import {useNavigate} from "react-router";
import {wizardArrangeTasksState, wizardProcessState} from "../../../store";
import WizardFrame from "../WizardFrame";
import {createWizardProcess, updateWizardProcess} from "../../../api";

export default function WizardArrange() {
    const [activeId, setActiveId] = useState<string | null>(null);
    const [overId, setOverId] = useState<string | null>(null);
    const navigate = useNavigate();
    const [tasks, setTasks] = useRecoilState<WizardArrangeTasks>(wizardArrangeTasksState)
    const [overlayType, setOverlayType] = useState<'button' | 'card' | null>(null);
    const [wizardProcess, setWizardProcess] = useRecoilState<WizardProcess>(wizardProcessState)

    const handleDragStart = (event: DragStartEvent) => {
        setActiveId(event.active.id as string)
        const activeContainer = event.active.data.current?.sortable.containerId;
        setOverlayType(activeContainer === 'available' ? 'button' : 'card')
    }

    const handleDragOver = ({over}: DragOverEvent) => {
        setOverId(over?.id as string || null)
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const taskName = event.currentTarget.value;
        const activeIndex = tasks['available'].findIndex(task => task.name === taskName)
        const task = tasks['available'].find(task => task.name === taskName)
        setTasks(prev => ({
            ...prev,
            available: removeAtIndex(prev.available, activeIndex),
            configured: insertAtIndex(prev.configured, prev.configured.length, task)
        }))
    }

    const handleDragEnd = ({active, over}: DragEndEvent) => {
        setOverlayType(null)
        setOverId(null)
        setActiveId(null)
        if (!over) {
            return;
        }
        if (active.id !== over.id) {
            const availableTasks = tasks['available'];
            const configuredTasks = tasks['configured'];
            const activeContainer = active.data.current?.sortable.containerId;
            const overContainer = over.data.current?.sortable.containerId || over.id;
            const activeIndex = active.data.current?.sortable.index;
            const overIndex = over.id === 'empty_slot' ? (overContainer === 'available' ? availableTasks : configuredTasks).length : over.data.current?.sortable.index || 0;

            if (activeContainer === overContainer) {
                setTasks(prev => ({
                    ...prev,
                    [activeContainer]: arrayMove(tasks[activeContainer], activeIndex, overIndex)
                }))
            } else {
                const task = tasks[activeContainer].find(task => task.name === active.id)
                setTasks(prev => ({
                    ...prev,
                    [activeContainer]: removeAtIndex(tasks[activeContainer], activeIndex),
                    [overContainer]: insertAtIndex(tasks[overContainer], overIndex, task)
                }))
            }
        }
    };

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {distance: 2},
        }),
    );

    const nextDisabled = tasks.available.length > 0 || tasks.configured.some(task => !task.team || !task.tool)

    const handleNext = () => {
        if (wizardProcess.uuid) {
            updateWizardProcess({...wizardProcess, status: 'COMPLETE'})
                .then(response => {
                    navigate(`/process/${response.processUuid}`);
                    setWizardProcess({uuid: '', templateUuid: '', name: '', tools: [], teams: [], tasks: []})
                })
                .catch(error => console.error(error))
        } else {
            createWizardProcess({...wizardProcess, status: 'COMPLETE'})
                .then(response => {
                    navigate(`/process/${response.processUuid}`);
                    setWizardProcess({uuid: '', templateUuid: '', name: '', tools: [], teams: [], tasks: []})
                }).catch(error => console.error(error))
        }
    }

    return (
        <WizardFrame prev={() => navigate('/wizard/teams-and-tools')}
                     next={handleNext}
                     nextDisabled={nextDisabled}
                     nextLabel={"Save and exit"}>
            <DndContext
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
            >
                <h2>Let's organize your process: {wizardProcess.name}</h2>
                <DroppableButtonContainer onClick={handleButtonClick}
                                          id="available"
                                          activeId={activeId}
                                          items={tasks['available'].map(item => item.name)}/>
                <div className="mt-5 mb-5"><span>Drag and drop your tasks in the order they are performed. Connect the responsible team and tool used and add the task attribute.</span></div>
                <DroppableCardContainer id="configured"
                                        activeId={activeId}
                                        overId={overId}
                                        items={tasks['configured'].map(item => item.name)}/>
                <DraggableOverlay activeId={activeId} overlayType={overlayType}/>
            </DndContext>
        </WizardFrame>
    );
};











