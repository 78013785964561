import React, {useEffect} from "react";
import {getUser} from "../api";
import {useSetRecoilState} from "recoil";
import {userState} from "../store";
import {CenterContainer} from "../components/CenterContainer";

export default function LandingView() {
    const setUser = useSetRecoilState(userState)
    useEffect(() => {
        getUser().then(data => setUser(data)).catch(error => setUser(null))
    });
    return (
        <CenterContainer>
            Landing page
        </CenterContainer>
    )
}