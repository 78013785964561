import {Job, ProcessTask, ProcessTeam} from "../../interfaces";
import {Form, FormControl, Table} from "react-bootstrap";
import React, {FormEvent, useState} from "react";
import {useSetRecoilState} from "recoil";
import {processTasksState} from "../../store";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable} from "@dnd-kit/sortable";
import {MouseSensor} from "../wizard/arrange/MouseSensor";

interface JobsListProps {
    task: ProcessTask,
    editMode: boolean,
    teams: ProcessTeam[]
}

export const JobsList = ({task, teams, editMode}: JobsListProps) => {
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const setProcessTasks = useSetRecoilState(processTasksState)
    const [jobNameInput, setJobNameInput] = useState<string | null>(null)
    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;
        if (over && active.id !== over.id) {
            const oldIndex = task.jobs?.findIndex((job: Job) => job.uuid === active.id) || 0;
            const newIndex = task.jobs?.findIndex((job: Job) => job.uuid === over.id) || 0;
            setProcessTasks(prev =>
                prev.map(prevTask => prevTask.uuid === task.uuid ?
                    {
                        ...prevTask,
                        jobs: arrayMove(prevTask.jobs, oldIndex, newIndex)
                    } : prevTask))
        }
    };
    const handleJobAdd = (event: FormEvent) => {
        event.preventDefault();
        const newJob: Job = {
            dependsOnJobs: [],
            description: "",
            duration: 0,
            name: jobNameInput || '',
            optional: false,
            team: task.team,
            uuid: crypto.randomUUID()
        }
        setJobNameInput(null)
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    jobs: [...(prevTask.jobs || []), newJob]
                } : prevTask))
    }

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            {(task.jobs || editMode) && (<Table size="sm" borderless={false} className="mt-3">
                <thead>
                <tr>
                    <th>
                        No
                    </th>
                    <th>
                        Job name
                    </th>
                    <th>
                        Team
                    </th>
                </tr>
                </thead>
                <tbody>
                    <SortableContext items={(task.jobs || []).map((job) => job.uuid)}>
                        {task.jobs?.map((job, index) => (
                            <SortableJobRow key={job.uuid}
                                            job={job}
                                            index={index}
                                            editMode={editMode}
                                            teams={teams}
                                            task={task}/>))}
                            </SortableContext>
                        {editMode && (<tr>
                            <td className="text-center" colSpan={6}>
                        <Form onSubmit={handleJobAdd}>
                            <FormControl
                                onSubmit={handleJobAdd}
                                type="input"
                                required
                                minLength={3}
                                maxLength={40}
                                value={jobNameInput || ''}
                                onChange={e => setJobNameInput(e.currentTarget.value)}
                                className="plain-input-sm"
                                plaintext
                                placeholder="Add a job and press enter"
                            ></FormControl>
                        </Form>
                    </td>
                </tr>)}
                </tbody>
            </Table>)
            }
        </DndContext>
    )
}

interface SortableJobRowProps {
    job: Job
    index: number,
        editMode
:
    boolean,
        teams
:
    ProcessTeam[]
    task: ProcessTask
}

const SortableJobRow = ({
    job, index, editMode, teams, task
}: SortableJobRowProps) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: job.uuid});

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'none',
        transition: transition ?? 'transform 150ms ease', // Adds a default smooth transition
    };
    const setProcessTasks = useSetRecoilState(processTasksState);
    const handleTeamSelect = (event: React.ChangeEvent<HTMLSelectElement>, jobUuid: string) => {
        event.stopPropagation();
        const teamUuid = event.currentTarget.value;
        const foundTeam = teams.find(team => team?.uuid === teamUuid)!;
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    jobs: prevTask.jobs?.map(job => job.uuid === jobUuid ? {...job, team: foundTeam} : job)
                } : prevTask))
    }
    const handleJobNameChange = (event: React.ChangeEvent<any>, jobUuid: string) => {
        event.stopPropagation();
        const name = event.currentTarget.value;
        setProcessTasks(prev =>
            prev.map(prevTask => prevTask.uuid === task.uuid ?
                {
                    ...prevTask,
                    jobs: prevTask.jobs?.map(job => job.uuid === jobUuid ? {...job, name} : job)
                } : prevTask))
    }
    return (
        <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <td>
                <span>{index + 1}</span>
            </td>
            <td>
                <Form.Control
                    type="input"
                    className={'plain-input-sm text-truncate' + (editMode ? 'pe-4' : '')}
                    disabled={!editMode}
                    data-no-dnd="true"
                    onChange={e => handleJobNameChange(e, job.uuid)}
                    value={job?.name}
                ></Form.Control>
            </td>
            <td>
                <Form.Select onChange={e => handleTeamSelect(e, job.uuid)}
                             className={'plain-input-sm pointer text-truncate' + (editMode ? 'pe-4' : '')}
                             disabled={!editMode}
                             data-no-dnd="true"
                             onClick={e => e.stopPropagation()}
                             value={job?.team?.uuid}>
                    {teams.map(team => (
                        <option key={team?.uuid} value={team?.uuid}>{team.name}</option>
                    ))}
                </Form.Select>
            </td>
        </tr>)
}