import React from "react";
import WizardProgress from "./WizardProgress";
import {Button, Container, Navbar} from "react-bootstrap";
import {FullContainer} from "../../components/FullContainer";

export interface WizardFrameProps {
    children: any
    next?: () => void
    prev?: () => void
    nextDisabled?: boolean
    nextLabel?: string
}

export default function WizardFrame(props: WizardFrameProps) {

    return (<>
        <FullContainer>
            <>
                <WizardProgress/>
                {props.children}
            </>
        </FullContainer>
        <Navbar fixed="bottom" bg="white">
            <Container className="d-flex justify-content-end mb-2 mt-auto">
                {props.prev && (<Button className="ms-auto ps-5 pe-5 me-3" variant="outline-secondary"
                                        onClick={props?.prev}>Back</Button>)}
                {props.next && (<Button className="ps-5 pe-5" variant="secondary"
                                        disabled={props.nextDisabled}
                                        onClick={props?.next}>{props.nextLabel || 'Next'}</Button>)}
            </Container>
        </Navbar>
    </>)
}