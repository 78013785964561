import {ProcessTask, ProcessTeam, ProcessTool} from "../../interfaces";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {processState, processTasksState} from "../../store";
import React, {useEffect, useState} from "react";
import {findRelatedTasks} from "./graph/ProcessUtils";
import {Accordion, Col, Form, Row} from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';
import {JobsList} from "./JobsList";
import { NumericFormat } from 'react-number-format';

interface TaskListRowProps {
    task: ProcessTask,
    editMode?: boolean,
    teams: ProcessTeam[],
    tools: ProcessTool[],
    toggleAccordion: (key: string) => void
}

export const TaskListRow = ({task, teams, tools, editMode, toggleAccordion}: TaskListRowProps) => {
    const setProcessTasks = useSetRecoilState(processTasksState)
    const process = useRecoilValue(processState)
    const [relatedTasks, setRelatedTasks] = useState<{
        dependsOn: ProcessTask[],
        dependedBy: ProcessTask[]
    } | null>(null)

    const handleOptionalClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        setProcessTasks(prev => prev.map(t => t.uuid === task.uuid ? {...t, optional: !t.optional} : t))
    }
    const handleTeamSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.stopPropagation();
        const teamUuid = event.currentTarget.value;
        const foundTeam = teams.find(team => team?.uuid === teamUuid)!;
        setProcessTasks(prev => prev.map(t => t.uuid === task.uuid ? {...t, team: foundTeam} : t))
    }
    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.target.reportValidity();
        const duration = (event.currentTarget.value.replace(' days', '')) as any
        setProcessTasks(prev => prev.map(t => t.uuid === task.uuid ? {...t, duration} : t))
    }
    const handleToolSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.stopPropagation();
        const toolUuid = event.currentTarget.value;
        const foundTool = tools.find(tool => tool?.uuid === toolUuid)!;
        setProcessTasks(prev => prev.map(t => t.uuid === task.uuid ? {...t, tool: foundTool} : t))
    }
    const handleDescriptionUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.stopPropagation();
        const description = event.currentTarget.value;
        setProcessTasks(prev => prev.map(t => t.uuid === task.uuid ? {...t, description} : t))
    }

    useEffect(() => {
        setRelatedTasks(findRelatedTasks(task.uuid, process.process.tasks, process.process.links));
    }, [task, process.process.tasks, process.process.links]);

    return (<>
        <Accordion.Item eventKey={task.uuid}>
            <Accordion.Header className="narrow-header">
                {task.name}
            </Accordion.Header>
            <Accordion.Body className="small">
                <Row>
                    <Col md={4}><strong>Responsible team</strong></Col>
                    <Col md={8}><Form.Select onChange={handleTeamSelect}
                                             className={'plain-input-sm pointer text-truncate' + (editMode ? 'pe-4' : '')}
                                             disabled={!editMode}
                                             onClick={e => e.stopPropagation()}
                                             value={task.team?.uuid}>
                        {teams.map(team => (
                            <option key={team?.uuid} value={team?.uuid}>{team.name}</option>
                        ))}
                    </Form.Select></Col>
                </Row>
                <Row>
                    <Col md={4}><strong>Primary tool</strong></Col>
                    <Col md={8}><Form.Select onChange={handleToolSelect}
                                             className={'plain-input-sm pointer text-truncate ' + (editMode ? 'pe-4' : '')}
                                             disabled={!editMode}
                                             onClick={e => e.stopPropagation()}
                                             value={task.tool?.uuid}>
                        {tools.map(tool => (
                            <option key={tool?.uuid} value={tool?.uuid}>{tool.name}</option>
                        ))}
                    </Form.Select></Col>
                </Row>
                <Row>
                    <Col md={4}><strong>Time budget</strong></Col>
                    <Col md={8}>
                            <NumericFormat
                                className="plain-input-sm"
                                suffix=" days"
                                required
                                disabled={!editMode}
                                value={task.duration}
                                onChange={handleDurationChange}
                                displayType="input"
                            />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}><strong>Optional</strong></Col>
                    <Col md={2}><Form.Check disabled={!editMode} onChange={handleOptionalClick}
                                            onClick={(e) => e.stopPropagation()}
                                            type="switch"
                                            checked={task.optional}></Form.Check></Col>
                </Row>
                {!!relatedTasks?.dependsOn.length && (<Row>
                    <Col md={4}><strong>Depends on</strong></Col>
                    <Col md={8}>{!!relatedTasks?.dependsOn.length &&
                        relatedTasks?.dependsOn
                            .map(source => <div className="pointer link-text"
                                                onClick={() => toggleAccordion(source.uuid)}
                                                key={`${task.uuid}_after_${source.uuid}`}>{source.name} </div>)}
                    </Col>
                </Row>)}
                {!!relatedTasks?.dependedBy.length && (<Row>
                    <Col md={4}><strong>Required for</strong></Col>
                    <Col md={8}>{relatedTasks?.dependedBy
                        .map(source => <div className="pointer link-text" onClick={() => toggleAccordion(source.uuid)}
                                            key={`${task.uuid}_before_${source.uuid}`}>{source.name} </div>)}
                    </Col>
                </Row>)}
                {(task.description || editMode) && (<Row>
                    <Col md={4}><strong>Description</strong></Col>
                    <Col md={8}>
                        <TextareaAutosize
                            className="plain-input mt-1"
                            value={task.description}
                            disabled={!editMode}
                            id={`${task.uuid}_description`}
                            placeholder="Enter text"
                            style={{
                                width: '100%'
                            }}
                            onChange={handleDescriptionUpdate}>
                        </TextareaAutosize>
                    </Col>
                </Row>)}
                <Row>
                    <Col md={12}>
                        <JobsList task={task} teams={teams} editMode={!!editMode}/>
                    </Col>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    </>)
}