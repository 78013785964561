import React, {useEffect, useState} from "react";
import {
    cancelInvitation,
    createInvitation,
    deleteInvitation,
    getOrganizationInvitations,
    getOrganizationMembers,
    removeOrganizationMember
} from "../api";
import {OrganizationMember, UserInvitation} from "../interfaces";
import {Badge, Button, Card, Col, Form, FormControl, Row} from "react-bootstrap";
import {FullContainer} from "../components/FullContainer";
import {useRecoilValue} from "recoil";
import {organizationState, organizationUuidState} from "../store";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import {ArrowUturnDownIcon} from "@heroicons/react/24/solid";

interface MemberCardProps {
    member: OrganizationMember,
    deleteAllowed: boolean,
    handleDelete: (member: OrganizationMember) => void
}

function MemberCard(props: MemberCardProps) {
    return (<Row>
        <Card className="list-hover-card">
            <Card.Body>
                <Row>
                    <Col md={5} className="d-flex align-items-center ">
                        <Card.Text className="text-start">
                            {props.member?.name}
                        </Card.Text>
                    </Col>
                    <Col md={3} className="d-flex align-items-center">
                        <Card.Text className="text-start">
                            {props.member?.email}
                        </Card.Text>
                    </Col>
                    <Col md={2} className="d-flex align-items-center justify-content-end">
                        <Badge bg={props.member.role === 'OWNER' ? 'primary' : 'secondary'}
                               className="me-2">{props.member.role}</Badge>
                    </Col>
                    <Col md={2} className="d-flex align-items-center justify-content-end">
                        {props.deleteAllowed && props.member.role !== 'OWNER' &&
                            (<Button
                                onClick={() => props.handleDelete(props.member)}
                                className="d-flex align-items-center me-2" variant="outline-danger"
                                size="sm"><XMarkIcon className="icon-style"/>Remove</Button>)}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Row>)
}

interface InvitationCardProps {
    invitation: UserInvitation,
    canRevoke: boolean,
    handleCancel: (invitation: UserInvitation) => void,
    handleDelete: (invitation: UserInvitation) => void,
}

function InvitationCard(props: InvitationCardProps) {
    return (<Row>
        <Card className="list-hover-card">
            <Card.Body>
                <Row>
                    <Col xs="6" md={6} className="d-flex align-items-center">
                        <Card.Text className="text-start">
                            {props.invitation?.email}
                        </Card.Text>
                    </Col>
                    <Col xs="3" md={4} className="d-flex align-items-center justify-content-end">
                        <Badge bg={props.invitation.status === 'PENDING' ? 'primary' : 'secondary'}
                               className="me-2">{props.invitation.status}</Badge>
                    </Col>
                    <Col xs="3"  md={2} className="d-flex align-items-center justify-content-end">
                        {props.canRevoke && props.invitation.status === 'PENDING' &&
                            (<Button
                                onClick={() => props.handleCancel(props.invitation)}
                                className="d-flex align-items-center me-2" variant="outline-danger"
                                size="sm"><ArrowUturnDownIcon className="icon-style"/>Cancel</Button>)}
                        {props.canRevoke && props.invitation.status !== 'PENDING' &&
                            (<Button
                                onClick={() => props.handleDelete(props.invitation)}
                                className="d-flex align-items-center me-2" variant="outline-danger"
                                size="sm"><XMarkIcon className="icon-style"/>Delete</Button>)}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Row>)
}

export function ManageUsersView() {
    const organization = useRecoilValue(organizationState);
    const [invitations, setInvitations] = useState<UserInvitation[]>([])
    const [members, setMembers] = useState<OrganizationMember[]>([])
    const organizationUuid = useRecoilValue(organizationUuidState)
    const isOwner = organization?.roles.includes("OWNER") || false;
    const [inviteEmail, setInviteEmail] = useState<string>('')

    useEffect(() => {
        getOrganizationInvitations()
            .then(data => setInvitations(data))
            .catch(error => console.error(error))
        getOrganizationMembers()
            .then(data => setMembers(data))
            .catch(error => console.error(error))
    }, [organizationUuid]);


    const handleDeleteMember = (member: OrganizationMember) => {
        removeOrganizationMember(member.uuid!)
            .then(_ => getOrganizationMembers())
            .then(data => setMembers(data))
            .catch(error => console.error(error))
    }

    const handleCancelInvitation = (invitation: UserInvitation) => {
        cancelInvitation(invitation.uuid!)
            .then(_ => getOrganizationInvitations())
            .then(data => setInvitations(data))
            .catch(error => console.error(error))
    }

    const handleDeleteInvitation = (invitation: UserInvitation) => {
        deleteInvitation(invitation.uuid!)
            .then(_ => getOrganizationInvitations())
            .then(data => setInvitations(data))
            .catch(error => console.error(error))
    }

    const handleCreateInvitation = (event: React.FormEvent) => {
        event.preventDefault();
        createInvitation(inviteEmail)
            .then(_ => setInviteEmail(''))
            .then(_ => getOrganizationInvitations())
            .then(data => setInvitations(data))
            .catch(error => console.error(error))
    }

    return <FullContainer>
        <div className="mb-5">
            <h2>Manage {organization?.name} users</h2>
        </div>
        <div className="mb-5">
            <div><h5>Active users and roles</h5></div>
            <div className="w-75">
                {members.map(member => <MemberCard
                    key={member.uuid} member={member} deleteAllowed={isOwner}
                    handleDelete={handleDeleteMember}></MemberCard>)}
            </div>
        </div>
        <div className="mb-5 w-75">
            <div><h5>Pending invitations</h5></div>
            <div>
                {invitations.map(invite => <InvitationCard
                    key={invite.uuid}
                    invitation={invite}
                    canRevoke={isOwner}
                    handleCancel={handleCancelInvitation}
                    handleDelete={handleDeleteInvitation}
                ></InvitationCard>)}
            </div>
            {invitations?.length === 0 && <div>
                <span className="text-muted">You do not have any pending invitations</span>
            </div>}
        </div>

        <Form onSubmit={handleCreateInvitation} className="mb-5">
            <div className="w-75 d-flex">
                <FormControl placeholder="Enter email to invite..."
                             className="me-4"
                             value={inviteEmail}
                             type="email"
                             required={true}
                             onChange={e => setInviteEmail(e.currentTarget.value)}></FormControl>
                <Button variant="secondary" type="submit">Invite</Button>
            </div>
        </Form>
    </FullContainer>
}