import {MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import {dia, elementTools, linkTools, shapes} from "@joint/core";
import CellView = dia.CellView;
import {useSetRecoilState} from "recoil";
import {processActiveTaskUuid} from "../../../store";

export const usePaper = (canvasRef: MutableRefObject<HTMLDivElement | null>, graphRef: MutableRefObject<dia.Graph | null>, editMode: boolean) => {
    const paperRef = useRef<dia.Paper | null>(null);
    const [paperReady, setPaperReady] = useState<boolean>(false)
    const setActiveTask = useSetRecoilState(processActiveTaskUuid)

    const setActive = useCallback((cell: CellView) => setActiveTask(cell.model.id as string), [setActiveTask]);

    useEffect(() => {
        const interactiveOptions = {linkMove: false, elementMove: true, addLinkFromMagnet: true};

        paperRef.current?.setInteractivity(editMode && interactiveOptions)

        const hideTools = (cellView: CellView) => cellView.hideTools();
        const showLinkTools = (cellView: CellView) => {
            if (editMode) {
                if (!cellView.hasTools()) {
                    cellView.addTools(new dia.ToolsView({
                        tools: [
                            new linkTools.Remove({
                                distance: '50%',
                                scale: 2
                            })
                        ]
                    }));
                }
                cellView.showTools();
            }
        };
        const showElementTools = (cellView: CellView) => {
            if (editMode) {
                if (!cellView.hasTools()) {
                    cellView.addTools(new dia.ToolsView({
                        tools: [
                            new elementTools.Connect({
                                offset: {
                                    x: 70,
                                    y: 60
                                },
                                scale: 2
                            }),
                            new elementTools.Remove({
                                offset: {
                                    x: 110,
                                    y: 60
                                },
                                scale: 2
                            })
                        ]
                    }));
                }
                cellView.showTools();
            }
        }

        paperRef.current?.on('link:mouseenter', showLinkTools);
        paperRef.current?.on('link:mouseleave', hideTools);
        paperRef.current?.on('element:mouseenter', showElementTools);
        paperRef.current?.on('element:mouseleave', hideTools);
        paperRef.current?.on('element:pointerdown', setActive);

        return () => {
            paperRef.current?.off('link:mouseenter', showLinkTools)
            paperRef.current?.off('link:mouseleave', hideTools)
            paperRef.current?.off('element:mouseenter', showElementTools)
            paperRef.current?.off('element:mouseleave', hideTools)
            paperRef.current?.off('element:pointerdown', setActive);
        }
    }, [editMode, paperReady, setActive]);

    useEffect(() => {
        paperRef.current = new dia.Paper({
            model: graphRef.current!,
            cellViewNamespace: shapes,
            width: '100%',
            height: '100%',
            gridSize: 20,
            async: true,
            sorting: dia.Paper.sorting.APPROX,
            clickThreshold: 10,
            linkPinning: false,

            background: {
                color: '#f9f9f9',
                opacity: 0.1
            },
            interactive: false,
            defaultConnector: {
                name: 'rounded',
                args: {
                    cornerType: 'cubic',
                    cornerRadius: 4,
                },
            },
            defaultAnchor: {
                name: 'modelCenter',
            },
            defaultConnectionPoint: {
                name: 'rectangle',
            },
            validateConnection: (cellViewS: dia.CellView, magnetS: SVGElement, cellViewT: dia.CellView, magnetT: SVGElement, end: dia.LinkEnd, linkView: dia.LinkView) => {
                if (cellViewT === cellViewS) {
                    return false;
                }
                if (!cellViewT || cellViewT.model.isLink()) {
                    return false;
                }
                const {sourceId, targetId} = {sourceId: cellViewS.model.id, targetId: cellViewT.model.id}
                return !graphRef.current!.getLinks().find(link => {
                    let linkSourceId = link.source().id;
                    let linkTargetId = link.target().id;
                    return (linkSourceId === sourceId && linkTargetId === targetId) || (linkSourceId === targetId && linkTargetId === sourceId)
                });
            },
        });
        setPaperReady(true)
        canvasRef.current?.replaceChildren(paperRef.current.el);

        return () => {
            setPaperReady(false)
            paperRef.current!.remove();
            paperRef.current = null;
        };
    }, []);

    return {
        paperRef
    }
}