import {useRecoilState} from "recoil";
import React from "react";
import {WizardTask} from "../../../interfaces";
import {Button, Card, Form} from "react-bootstrap";
import {wizardProcessState} from "../../../store";

export default function TaskCard(props: { style: any, title: string }) {
    const [wizardProcess, setWizardProcess] = useRecoilState(wizardProcessState)
    const task = wizardProcess.tasks?.filter(task => task.selected).find(x => x.name === props.title)
    const teams = wizardProcess.teams?.filter(team => team.selected)
    const tools = wizardProcess.tools?.filter(team => team.selected)

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optional = event.currentTarget.checked;
        setWizardProcess((prev) => ({
            ...prev,
            tasks: prev.tasks.map((item: WizardTask) => item.name === props.title ? {...item, ...{optional}} : item)
        }))
    }

    const handleToolChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const tool = wizardProcess.tools?.find(x => x.name === event.currentTarget.value)
        setWizardProcess((prev) => ({
            ...prev,
            tasks: prev.tasks.map((item: WizardTask) => item.name === props.title ? {...item, tool} : item)
        }))
    }

    const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const team = wizardProcess.teams?.find(x => x.name === event.currentTarget.value)
        setWizardProcess((prev) => ({
            ...prev,
            tasks: prev.tasks.map((item: WizardTask) => item.name === props.title ? {...item, team} : item)
        }))
    }

    return (
        <Card className="me-3 mb-3 card-slot" style={props.style}>
            <Card.Body>
                <Button variant="primary" size="sm" className="w-100">
                    <span style={{fontSize: '0.80rem'}}>{props.title}</span>
                </Button>
                <Form.Select data-no-dnd="true" size="sm"
                             value={task?.team?.name || ''}
                             onChange={handleTeamChange}
                             className="mt-3">
                    <option key={`team_${props.title}_blank`} hidden value="-1">Responsible team</option>
                    {teams.map((item, index) => (
                        <option key={`team_${props.title}_${index}`} value={item.name}>{item.name}</option>
                    ))}
                </Form.Select>
                <Form.Select data-no-dnd="true" size="sm"
                             className="mt-3"
                             value={task?.tool?.name || ''}
                             onChange={handleToolChange}>
                    <option key={`tool_${props.title}_blank`} hidden value="-1">Tool used</option>
                    {tools.map((item, index) => (
                        <option key={`tool_${props.title}_${index}`} value={item.name}>{item.name}</option>
                    ))}
                </Form.Select>
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className={"d-flex align-content-center"} style={{fontSize: '0.80rem'}}>Optional task</div>
                    <Form.Check className="ms-2"
                                data-no-dnd="true"
                                type="switch"
                                checked={task?.optional || false}
                                onChange={handleCheckboxChange}
                    />
                </div>
            </Card.Body>
        </Card>);
}