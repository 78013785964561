import React from "react";
import {CenterContainer} from "../components/CenterContainer";

export default function UploadView() {

    return (
        <CenterContainer>
            Uploader here
        </CenterContainer>
    )
}