import {useRecoilState} from "recoil";
import {processTasksState} from "../../store";
import {getTemplate, saveTemplateTask} from "../../api";
import {Badge} from "react-bootstrap";
import ItemAddModal from "../wizard/ItemAddModal";
import React, {useEffect, useState} from "react";
import {ProcessTeam, ProcessTool, WizardTask} from "../../interfaces";

interface AddTasksPanelProps {
    templateUuid: string;
    teams: ProcessTeam[],
    tools: ProcessTool[],
}

const AddTasksPanel = React.memo(({templateUuid, teams, tools}: AddTasksPanelProps) => {
    const [availableTasks, setAvailableTasks] = useState<WizardTask[]>([])
    const [processTasks, setProcessTasks] = useRecoilState(processTasksState)
    const [processTaskNames, setProcessTaskNames] = useState<string[]>([])
    const [templateTasks, setTemplateTasks] = useState<WizardTask[]>([])

    useEffect(() => {
        getTemplate(templateUuid)
            .then(template => template.tasks)
            .then(tasks => setTemplateTasks(tasks))
            .catch(error => console.error(error))
    }, [templateUuid]);

    useEffect(() => {
        const availableTasks = templateTasks.filter(task => !processTaskNames.includes(task.name))
        setAvailableTasks(availableTasks)
    }, [processTaskNames, processTasks, templateTasks]);

    useEffect(() => {
        setProcessTaskNames(processTasks.map(task => task.name))
    }, [processTasks]);

    const saveNewItem = async (name: string) => {
        saveTemplateTask({name, templateUuid})
            .then(response => response.uuid)
            .then(uuid => setProcessTask(name, uuid))
            .then()
    }

    const setProcessTask = (name: string, uuid?: string) => {
        setAvailableTasks(prev => prev.filter(task => task.name !== name))

        setProcessTasks(prev => [...prev, {
            name,
            uuid: uuid ? uuid : crypto.randomUUID(),
            description: '',
            optional: false,
            duration: 0,
            team: teams.find(team => !!team)!,
            tool: tools.find(tools => !!tools)!,
            jobs: []
        }])
    }

    return (
        <div className="d-flex flex-row flex-wrap justify-content-center align-items-center mt-3">
            <div>Add more:</div>
            {availableTasks.map((item, index) => (
                <Badge onClick={() => setProcessTask(item.name, item.uuid)} className="m-1 pointer"
                       key={`task_${index}`}
                       id={`task_${index}`}>{item.name}
                </Badge>
            ))}
            <ItemAddModal handleNewItem={saveNewItem} size="16px" itemType='tasks'/>
        </div>
    )
})

export default AddTasksPanel;