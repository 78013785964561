import {Button} from "react-bootstrap";
import {DragOverlay} from "@dnd-kit/core";
import React from "react";
import SortableItem from "./SortableItem";
import TaskCard from "./TaskCard";

export interface DraggableOverlayProps {
    activeId: string | null,
    overlayType: 'card' | 'button' | null,
}

export default function DraggableOverlay({activeId, overlayType}: DraggableOverlayProps) {
    return (<DragOverlay>
        {activeId && overlayType === 'button' ? (
            <SortableItem id={`btn_overlay_${activeId}`}
                          invisible={false}>
                <Button className="me-3 mt-3"
                        id="overlay-btn"
                        variant="outline-primary">
                    {activeId}
                </Button>
            </SortableItem>
        ) : null}
        {activeId && overlayType === 'card' ? (
            <SortableItem id={`btn_overlay_${activeId}`}
                          invisible={false}>
                <TaskCard style={{
                    width: '12rem',
                    height: '16rem',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                }}
                          title={activeId}></TaskCard>
            </SortableItem>
        ) : null}
    </DragOverlay>)
}