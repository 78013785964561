import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Col, Dropdown, Modal, Nav, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import {CenterContainer} from "../components/CenterContainer";
import {organizationState, organizationUuidState, userState, wizardProcessState} from "../store";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import PlusIcon from '@heroicons/react/24/solid/PlusIcon'
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon'
import ChevronDownIcon from '@heroicons/react/24/solid/ChevronDownIcon'
import {getProcessList, getWizardProcess, getWizardProcessList} from "../api";
import {Process, WizardProcess} from "../interfaces";
import {FullContainer} from "../components/FullContainer";


export default function HomeView() {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const organization = useRecoilValue(organizationState);
    const [organizationUuid, setOrganizationUuid] = useRecoilState(organizationUuidState);
    const user = useRecoilValue(userState);
    const setWizardProcess = useSetRecoilState(wizardProcessState)
    const [wizardProcessList, setWizardProcessList] = useState<WizardProcess[]>([])
    const [processList, setProcessList] = useState<Process[]>([])

    useEffect(() => {
        getWizardProcessList()
            .then(result => setWizardProcessList(result || []))
            .catch(error => console.error(error))
        getProcessList()
            .then(result => setProcessList(result || []))
            .catch(error => console.error(error))
    }, [organizationUuid]);

    const latestProcesses = [...processList, ...wizardProcessList]
        .sort((a, b) => b.updatedAt!.localeCompare(a.updatedAt!))
        .slice(0, 10);

    function navigateToProcess(process: Process | WizardProcess) {
        if ("status" in process) {
            getWizardProcess(process.uuid).then(data => {
                setWizardProcess(data)
                navigate(`/wizard/tasks`)
            })
        } else {
            navigate(`/process/${process.uuid}`)
        }
    }

    return (
        <FullContainer>
            <Row>
                <Col xs={3} md={2}>
                    <div className="ms-2">
                        <Row className="mt-3 mb-3">
                            <Dropdown as={ButtonGroup} style={{width: "max-content"}}>
                                <Button className="text-start custom-narrow-button"
                                        variant="outline-secondary">{organization?.name}</Button>
                                {user?.organizations?.length && <>
                                    <Dropdown.Toggle className="d-flex align-items-center" split
                                                     variant="outline-secondary"><ChevronDownIcon className="icon-style"/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {user.organizations?.map(org => (
                                            <Dropdown.Item key={org.uuid}
                                                           onClick={() => setOrganizationUuid(org.uuid)}>{org.name}
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Item onClick={() => navigate("/setup-organization")}
                                                       className="d-flex align-items-center">
                                            <PlusIcon className="me-1 icon-style"/> Create organization</Dropdown.Item>
                                    </Dropdown.Menu>
                                </>}
                            </Dropdown>
                        </Row>
                        {latestProcesses.length > 0 && <strong>Recent processes</strong>}
                        {latestProcesses.map(process =>
                            <Nav.Link onClick={() => navigateToProcess(process)}
                                      key={process.uuid}>{process.name} {'status' in process ? (
                                <span className="ms-2 text-muted"><i>Draft</i></span>) : (<></>)}</Nav.Link>
                        )}
                        <Button size="sm" className="d-flex align-items-center mt-2"
                                onClick={() => navigate('/wizard/tasks')}>
                            <PlusIcon className="me-1 icon-style"/>Create new
                        </Button>
                        {latestProcesses.length > 0 &&
                            <Button size="sm" className="d-flex align-items-center mt-2"
                                    onClick={() => navigate('/process/list')}>
                                <Bars3Icon className="me-1 icon-style"/>View all
                            </Button>}
                    </div>
                </Col>

                <Col xs={9} md={9}>
                    <CenterContainer>
                        <Row className="w-75 d-flex justify-content-center">
                            <Col className="col-5">
                                <Row className="m-1">
                                    <Button onClick={() => setShowModal(true)} variant="primary" className="p-3">
                                        <strong>Create new process</strong>
                                    </Button>
                                </Row>
                                <Row className="m-2">
                                    <p style={{fontSize: '0.75rem'}} className="text-center">Jump into process wizard or
                                        upload an
                                        existing process
                                        description to get started.</p>
                                </Row>
                            </Col>
                            <Col className="col-5">
                                <Row className="m-1">
                                    <Button variant="outline-primary" onClick={() => navigate('/process/list')}
                                            className="p-3">
                                        <strong>Open saved process</strong>
                                    </Button>
                                </Row>
                                <Row className="m-2">
                                    <p style={{fontSize: '0.75rem'}} className="text-center">Open a previously saved
                                        process to
                                        modify or view</p>
                                </Row>
                            </Col>
                        </Row>

                        <Modal className="h-75 modal-lg" centered show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Body className="m-3">
                                <Row className="m-2 justify-content-center">
                                    <Col className="col-5">
                                        <Row className="m-1">
                                            <Button onClick={() => navigate("/wizard/tasks")} variant="outline-primary"
                                                    className="p-3">
                                                <strong>Go to wizard</strong>
                                            </Button>
                                        </Row>
                                    </Col>
                                    <Col className="col-5">
                                        <Row className="m-1">
                                            <Button onClick={() => navigate("/upload")} variant="outline-primary"
                                                    className="p-3">
                                                <strong>Upload a description</strong>
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Modal>
                    </CenterContainer>
                </Col>
            </Row>
        </FullContainer>
    );
}