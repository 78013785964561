import React, {useState} from "react";
import {Col, Nav, Navbar, Offcanvas, Row} from "react-bootstrap";
import Logo from "../logo.svg";
import {useRecoilState} from "recoil";
import {userState} from "../store";
import {Link} from "react-router-dom";
import {User} from "../interfaces";
import CogIcon from '@heroicons/react/24/solid/Cog6ToothIcon'
import EnvelopeIcon from '@heroicons/react/24/solid/EnvelopeIcon'
import SignOutIcon from '@heroicons/react/24/solid/ArrowRightEndOnRectangleIcon'
import UserIcon from '@heroicons/react/24/solid/UserCircleIcon'
import UsersIcon from '@heroicons/react/24/solid/UsersIcon'
import {useNavigate} from "react-router";

interface SidebarMenuProps {
    showSidebar: boolean;
    toggleSidebar: (showSidebar: boolean) => void;
    userImage: any;
    user: User | null;
}

interface SidebarRowProps {
    className?: string
    icon: any;
    label: string;
    onClick?: () => void;
    to?: string;
    toggleSidebar: (showSidebar: boolean) => void;
}

function SidebarDivider() {
    return <div className="ms-2 me-2">
        <hr></hr>
    </div>
}

function SidebarLink(props: SidebarRowProps) {
    const navigate = useNavigate();

    const doAction = () => {
        if (props.onClick) {
            props.onClick();
        } else if (props.to) {
            navigate(props.to);
        }
        props.toggleSidebar(false);
    }
    return (
        <>
            <Row className={`${props.className} mb-2`} onClick={doAction} style={{cursor: "pointer"}}>
                <Col xs={2} md={2} className="ms-3 d-flex align-items-center">
                    {props.icon}
                </Col>
                <Col className="ps-0">{props.label}</Col>
            </Row>
        </>
    )
}

function SidebarMenu({showSidebar, toggleSidebar, userImage, user}: SidebarMenuProps) {
    const style = {
        width: "300px",
        maxWidth: "100%",
    }
    const logout = () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/logout`
    }
    return (<>
        <Offcanvas className="text-muted" style={style} show={showSidebar} onHide={toggleSidebar} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="me-3">{userImage}</div>
                        <div className="text-muted small">{(`${user?.name}`).trim()}</div>
                    </div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="d-flex flex-column" style={{height: '100%'}}>
                    <div className="mb-auto">
                        <SidebarLink toggleSidebar={toggleSidebar} icon={<UserIcon className="icon-style"/>}
                                     label="Your Profile"
                                     to="/user-profile"></SidebarLink>
                        <SidebarLink toggleSidebar={toggleSidebar} icon={<EnvelopeIcon className="icon-style"/>}
                                     label="Manage Invites"
                                     to="/pending-invitations"></SidebarLink>
                        <SidebarDivider></SidebarDivider>
                        <SidebarLink toggleSidebar={toggleSidebar} icon={<CogIcon className="icon-style"/>}
                                     label="Organization Settings"
                                     to="/organization-settings"></SidebarLink>
                        <SidebarLink toggleSidebar={toggleSidebar} icon={<UsersIcon className="icon-style"/>}
                                     label="Manage Users"
                                     to="/manage-users"></SidebarLink>
                    </div>

                    <SidebarLink toggleSidebar={toggleSidebar} icon={<SignOutIcon className="icon-style"/>}
                                 label="Sign Out"
                                 onClick={logout}></SidebarLink>
                </div>
            </Offcanvas.Body>
        </Offcanvas></>);
}

export function TopNavBar() {
    const [user] = useRecoilState(userState)
    const [showSidebar, setShowSidebar] = useState(false);
    const toggleSidebar = () => setShowSidebar(!showSidebar);
    const brandIconStyle = {height: 35, width: 35};

    const userImage = (<div className="d-flex align-items-center" style={{cursor: "pointer"}} onClick={toggleSidebar}>
        <img className="ms-3 profile-picture"
             src={user?.picture}
             style={{height: 35, width: 35}}
             alt="profile"
        />
    </div>);

    return (
        <Navbar bg="light" data-bs-theme="light">
            <div className="ms-5 d-flex justify-content-between w-100">
                <div className="d-flex align-items-center">
                    <Navbar.Brand as={Link} to="/landing" className="d-flex align-items-center p-0 me-4">
                        <img
                            src={Logo}
                            style={brandIconStyle}
                            alt="processplot logo2"
                        />
                    </Navbar.Brand>
                    <Nav.Link as={Link} to="/home">Home</Nav.Link>
                </div>

                <div className="ms-auto me-5">
                    <Nav className="d-flex justify-content-end align-items-center ">
                        {!user?.email ?
                            (<Nav.Link href={`${process.env.REACT_APP_API_URL}/login`}>Sign in</Nav.Link>)
                            :
                            userImage
                        }
                        <SidebarMenu showSidebar={showSidebar}
                                     user={user}
                                     toggleSidebar={toggleSidebar}
                                     userImage={userImage}></SidebarMenu>
                    </Nav>
                </div>
            </div>
        </Navbar>
    )
}