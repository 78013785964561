import React, {useEffect, useState} from "react";
import {Badge, Button, Card, Col, FormControl, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {useRecoilState} from "recoil";
import {organizationUuidState, userState} from "../store";
import {FullContainer} from "../components/FullContainer";
import {Organization} from "../interfaces";
import {deleteOrganization, getUser, leaveOrganization, resetLoginPassword, updateUser} from "../api";
import {useNavigate} from "react-router";
import {conditionalWrap} from "../utils";
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon'
import SignOutIcon from '@heroicons/react/24/solid/ArrowRightEndOnRectangleIcon'
import CheckIcon from '@heroicons/react/24/solid/CheckIcon'


export function UserProfileView() {
    const [user, setUser] = useRecoilState(userState);
    const [organizationUuid, setOrganizationUuid] = useRecoilState(organizationUuidState);
    const navigate = useNavigate();
    const [userName, setUserName] = useState<string>('')
    const [showResetCheckmark, setShowResetCheckmark] = useState(false)

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setUserName(value)
    }

    const hasChanges = user?.name !== userName;

    useEffect(() => {
        setUserName(user?.name || '')
    }, [user?.name]);

    const handleLeave = (organization: Organization) => {
        leaveOrganization(organization.uuid)
            .catch(error => console.error(error))
            .then(_ => handlePostRemove());
    }

    const handleDelete = (organization: Organization) => {
        deleteOrganization(organization.uuid)
            .catch(error => console.error(error))
            .then(_ => handlePostRemove())
    }

    const handlePostRemove = async () => {
        return getUser()
            .then(data => {
                setUser(data)
                if (data.organizations.length === 0) {
                    throw Error("No organizations left");
                }
                const requiresOrgChange = !data.organizations?.find(org => org.uuid === organizationUuid)
                if (requiresOrgChange) {
                    setOrganizationUuid(data.organizations[0].uuid)
                }
            }).catch(_ => {
                setUser(null);
                navigate("/landing")
            })
    }

    const handleResetPassword = () => {
        setShowResetCheckmark(false)
        resetLoginPassword()
            .then(response => setShowResetCheckmark(true))
            .catch(error => console.error(error))
    }

    const handleSaveName = () => {
        updateUser({name: userName})
            .then(_ => getUser())
            .then(user => setUser(user))
            .catch(error => console.error(error))
    }

    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Cannot reset since only social login has been performed
        </Tooltip>
    );

    return <FullContainer>
        <h2>Here is your user profile</h2>
        <Row className="mt-5 f-flex flex-row">
            <Col xs="3" md="2" className="me-4">
                <div className="align-items-center">
                    <img className="ms-3 profile-picture"
                         src={user?.picture}
                         style={{height: 100, width: 100}}
                         alt="profile"
                    />
                </div>
            </Col>
            <Col>
                <Row className="mb-3">
                    <Col md="6">
                        <FormControl type="text" name="firstName" value={userName}
                                     onChange={onNameChange}></FormControl>
                    </Col>
                    {hasChanges && (<Col md="3">
                        <Button onClick={handleSaveName}>Save changes</Button>
                    </Col>)}

                </Row>
                <Row className="d-flex align-items-start">
                    <Col md="3" className="mb-3">
                        <FormControl type="text" disabled={true} value={user?.email}></FormControl>
                    </Col>
                    <Col md="6" xs="12" className="d-flex align-items-center">
                        {conditionalWrap(
                            !user?.resetPossible,
                            (children: any) => <OverlayTrigger
                                delay={{show: 150, hide: 200}}
                                overlay={renderTooltip}
                                placement="right"
                            >{children}</OverlayTrigger>,
                            <div>
                                <Button variant="secondary" disabled={!user?.resetPossible} onClick={handleResetPassword}>Send
                                me a password reset link</Button>
                            </div>
                        )}
                        {showResetCheckmark && (
                            <div className="ms-3"><CheckIcon className="icon-style" color="green"/></div>)}
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="mt-5 w-75">
            <h4>My organizations</h4>
            {user?.organizations?.map(organization => (
                <OrganizationListCard handleLeave={handleLeave}
                                      handleDelete={handleDelete}
                                      key={organization.uuid}
                                      organization={organization}/>
            ))}
        </Row>
        <Row className="mt-3">
            <Col>
                <Button onClick={() => navigate('/setup-organization')}>Add new</Button>
            </Col>
        </Row>
    </FullContainer>
}

interface OrganizationListCardProps {
    organization: Organization,
    handleLeave: (organization: Organization) => void,
    handleDelete: (organization: Organization) => void;
}

function OrganizationListCard(props: OrganizationListCardProps) {
    return (
        <Row>
            <Card className="list-hover-card">
                <Card.Body>
                    <Row>
                        <Col md={6} className="d-flex align-items-center">
                            <Card.Text className="text-start">
                                {props.organization.name}
                            </Card.Text>
                        </Col>
                        <Col md={3} className="d-flex align-items-center justify-content-end">
                            {props.organization.roles.map(role => <Badge
                                key={role}
                                bg={role === 'OWNER' ? 'primary' : 'secondary'}
                                className="me-2">{role}</Badge>)}
                        </Col>
                        <Col md={3} className="d-flex align-items-center justify-content-end">
                            {props.organization.roles.includes('OWNER') &&
                                <Button
                                    onClick={() => props.handleDelete(props.organization)}
                                    className="d-flex align-items-center me-2" variant="outline-danger"
                                    size="sm"><XMarkIcon className="icon-style"/>Delete</Button>}
                            {props.organization.roles.includes('MEMBER') &&
                                <Button
                                    onClick={() => props.handleLeave(props.organization)}
                                    className="d-flex align-items-center me-2" variant="outline-danger"
                                    size="sm"><SignOutIcon className="icon-style"/>Leave</Button>}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>)
}