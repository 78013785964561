import React, {useEffect, useState} from "react";
import {Badge, Button, Card, Col, Row} from "react-bootstrap";
import {acceptInvitation, declineInvitation, getInvitations} from "../api";
import {UserInvitation} from "../interfaces";
import {formatDate} from "../utils";
import {CenterContainer} from "../components/CenterContainer";
import {FullContainer} from "../components/FullContainer";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";

export function UserInvitationsListView() {

    const [invitations, setInvitations] = useState<UserInvitation[]>([]);

    useEffect(() => {
        getInvitations()
            .then(data => setInvitations(data))
            .catch(error => console.error(error))
    }, []);

    const handleAccept = (invitation: UserInvitation) => {
        acceptInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .catch(error => console.error(error))
    }

    const handleDecline = (invitation: UserInvitation) => {
        declineInvitation(invitation.uuid!)
            .then(_ => getInvitations())
            .then(data => setInvitations(data))
            .catch(error => console.error(error))
    }

    return (<>
        <FullContainer>
            {invitations.length ? (<>
                    <h2>You have been invited to join</h2>
                    <div className="pt-3">
                        {invitations?.map(invitation =>
                            <InvitationListCard key={invitation.uuid}
                                                invitation={invitation}
                                                onAccept={handleAccept}
                                                onDecline={handleDecline}/>
                        )}
                    </div>
                </>) :
                (<CenterContainer>You have no pending invites.</CenterContainer>)}
        </FullContainer>
    </>)
}

interface InvitationListCardProps {
    invitation: UserInvitation,
    onAccept: (invitation: UserInvitation) => void,
    onDecline: (invitation: UserInvitation) => void
}

const InvitationListCard = (props: InvitationListCardProps) => {
    const formattedDate = formatDate(props.invitation.createdAt!)

    const getStatus = (status?: string) => {
        return status;
    }

    function onAcceptClick() {
        props.onAccept(props.invitation)
    }

    function onDeclineClick(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        props.onDecline(props.invitation)
    }

    return (
        <Row>
            <Card className="list-hover-card">
                <Card.Body>
                    <Row>
                        <Col md={3} className="d-flex align-items-center">
                            <Card.Text className="text-start">
                                {props.invitation.organizationName}
                            </Card.Text>
                        </Col>
                        <Col md={3} className="d-flex align-items-center">
                            <Card.Text>
                                {props.invitation.invitedBy}
                            </Card.Text>
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                            <Card.Text>
                                {formattedDate}
                            </Card.Text>
                        </Col>
                        <Col md={2} className="d-flex align-items-center justify-content-start">
                            <Card.Text>
                                <Badge>{getStatus(props.invitation.status)}</Badge>
                            </Card.Text>
                        </Col>
                        <Col md={2} className="d-flex align-items-center">
                            {props.invitation.status === 'PENDING' && (<>
                                <Button className="me-3" onClick={onAcceptClick} variant={"outline-success"} size="sm">
                                    <CheckIcon style={{cursor: "pointer"}} className="icon-style ms-auto"/>
                                </Button>
                                <Button onClick={onDeclineClick} variant={"outline-danger"} size="sm">
                                    <XMarkIcon style={{cursor: "pointer"}} className="icon-style ms-auto"/>
                                </Button></>)}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>)
}