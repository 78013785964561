import {organizationUuidState} from './store';
import {OrganizationMember, ProcessTemplate, User, UserInvitation, WizardProcess, Process} from './interfaces';
import {getRecoil} from 'recoil-nexus';

function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(';').shift();
    } else {
        return undefined;
    }
}

const fetcher = (method: 'GET' | 'POST' | 'PUT' | 'DELETE', path: string, orgId?: string | null, body?: any) => fetch(`${process.env.REACT_APP_API_URL}${path}`, {
    headers: {
        ...(orgId) && {'X-Org-ID': orgId},
        'Accept': 'application/json',
        ...(['POST', 'PUT', 'DELETE'].includes(method) && {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
        })
    },
    credentials: 'include',
    method: method,
    ...(body && {body: JSON.stringify(body)})
}).then(async (response) => {
    const contentType = response.headers.get('Content-Type');
    let responseJson;
    if (contentType && contentType.includes('application/json')) {
        responseJson = await response.json() || {message: response.statusText};
    } else {
        responseJson = {message: await response.text() || response.statusText}
    }
    return response.ok ? Promise.resolve(responseJson) : Promise.reject(responseJson);
})


export const getUser = async (): Promise<User> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', '/user/info', organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveTemplate = async (name: string) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/template`, organizationUuid, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveTemplateTask = async ({templateUuid, name}: { templateUuid: string, name: string }) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/template/${templateUuid}/task`, organizationUuid, name);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveTeam = async (name: string) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/team`, organizationUuid, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTeams = async () => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', `/teams`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveTool = async (name: string) => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', `/tool`, organizationUuid, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTools = async () => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', `/tools`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTemplates = async (): Promise<ProcessTemplate[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', '/templates', organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getTemplate = async (templateUuid: string): Promise<ProcessTemplate> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('GET', `/template/${templateUuid}`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const createWizardProcess = async (process: WizardProcess): Promise<{
    wizardProcessUuid: string,
    processUuid: string
}> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('POST', '/wizard/process', organizationUuid, {
            process: {
                templateUuid: process.templateUuid,
                name: process.name,
                teams: process.teams,
                tools: process.tools,
                tasks: process.tasks
            }
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export const updateWizardProcess = async (process: WizardProcess): Promise<{
    wizardProcessUuid: string,
    processUuid: string
}> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return fetcher('PUT', `/wizard/process/${process.uuid}`, organizationUuid, {
            process: {
                templateUuid: process.templateUuid,
                name: process.name,
                teams: process.teams,
                tools: process.tools,
                tasks: process.tasks,
                ...(process.status && {status: process.status})
            }
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getWizardProcess = async (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/wizard/process/${uuid}`, organizationUuid).then(resp => resp.process)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getWizardProcessList = async (): Promise<WizardProcess[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/wizard/process/list`, organizationUuid).then(result => result.processes);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getProcessList = async (): Promise<Process[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/process/list`, organizationUuid)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getProcess = async (uuid: string): Promise<Process> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/process/${uuid}`, organizationUuid)
    } catch (e) {
        return Promise.reject(e);
    }
}

export const saveProcess = async (process: Process): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('POST', `/process/${process.uuid}`, organizationUuid, {
            process: process.process,
            name: process.name
        })
    } catch (e) {
        return Promise.reject(e);
    }
}


export const deleteWizardProcess = async (uuid: string): Promise<WizardProcess> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('DELETE', `/wizard/process/${uuid}`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const setupOrganization = async (request: { organizationName: string, invitedEmails: string[] }): Promise<{
    uuid: string
}> => {
    try {
        return await fetcher('POST', `/organization/setup`, null, {
            organizationName: request.organizationName,
            invitedEmails: request.invitedEmails
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getInvitation = async (uuid: string): Promise<UserInvitation> => {
    try {
        return await fetcher('GET', `/invitation/${uuid}`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getInvitations = async (): Promise<UserInvitation[]> => {
    try {
        return await fetcher('GET', `/invitation/list`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getOrganizationInvitations = async (): Promise<UserInvitation[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/organization/${organizationUuid}/invitations`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const acceptInvitation = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('PUT', `/invitation/${uuid}/accept`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const declineInvitation = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('PUT', `/invitation/${uuid}/decline`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const cancelInvitation = async (uuid: string): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('PUT', `/invitation/${uuid}/cancel`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const createInvitation = async (email: string): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('POST', `/invitation`, organizationUuid, {email});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const deleteInvitation = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('DELETE', `/invitation/${uuid}`);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const leaveOrganization = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('POST', `/organization/${uuid}/leave`, uuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const deleteOrganization = async (uuid: string): Promise<void> => {
    try {
        return await fetcher('DELETE', `/organization/${uuid}`, uuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const resetLoginPassword = async (): Promise<void> => {
    try {
        return await fetcher('POST', `/user/reset-password`);
    } catch (e) {
        return Promise.reject(e);
    }
}


export const updateUser = async ({name}: { name: string }): Promise<void> => {
    try {
        return await fetcher('POST', `/user/update`, null, {name});
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getOrganizationMembers = async (): Promise<OrganizationMember[]> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('GET', `/organization/${organizationUuid}/members`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const removeOrganizationMember = async (userUuid: string): Promise<void> => {
    const organizationUuid = getRecoil(organizationUuidState);
    try {
        return await fetcher('DELETE', `/organization/${organizationUuid}/member/${userUuid}`, organizationUuid);
    } catch (e) {
        return Promise.reject(e);
    }
}