import {useDroppable} from "@dnd-kit/core";
import {rectSortingStrategy, SortableContext} from "@dnd-kit/sortable";
import React from "react";

export default function DroppableElement({items, id, children}: {
    items: string[],
    id: string,
    children: any[]
}) {
    const {setNodeRef} = useDroppable({id});

    const style = {
        minHeight: "50px"
    }
    return (
        <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
            <div className="mt-2">
                <div style={style} ref={setNodeRef} className="d-flex flex-row justify-content-start">
                    {children}
                </div>
            </div>
        </SortableContext>
    );
}