import {Badge, Container} from "react-bootstrap";
import React from "react";
import {useLocation} from "react-router";

export default function WizardProgress() {
    const location = useLocation();

    const getCurrentStep = (location: string): number => {
        switch (location) {
            case '/wizard/tasks':
                return 1;
            case '/wizard/teams-and-tools':
                return 2;
            case '/wizard/arrange':
                return 3;
            default:
                return 1;
        }
    }

    const currentStep = getCurrentStep(location.pathname)
    const getButtonVariant = (step: number) => {
        return currentStep >= step ? 'primary' : 'secondary'
    }

    return (
        <Container className="d-flex justify-content-center mb-2">
            <Badge className="me-1" bg={getButtonVariant(1)}>1</Badge>
            <Badge className="me-1" bg={getButtonVariant(2)}>2</Badge>
            <Badge className="me-1" bg={getButtonVariant(3)}>3</Badge>
        </Container>
    );
}