import React from "react";
import {useRecoilState} from "recoil";
import {wizardProcessState} from "../../../store";
import WizardItemSelect from "../WizardItemSelect";
import WizardFrame from "../WizardFrame";
import {createWizardProcess, updateWizardProcess} from "../../../api";
import {useNavigate} from "react-router";

export default function WizardTeamsAndTools() {
    const [wizardProcess, setWizardProcess] = useRecoilState(wizardProcessState)
    const navigate = useNavigate()

    const handleNext = () => {
        if (wizardProcess.uuid) {
            updateWizardProcess(wizardProcess)
                .then(_ => navigate(`/wizard/arrange`))
                .catch(error => console.error(error))
        } else {
            createWizardProcess(wizardProcess)
                .then(result => setWizardProcess(prev => ({...prev, uuid: result.wizardProcessUuid})))
                .then(_ => navigate(`/wizard/arrange`))
                .catch(error => console.error(error))
        }
    }

    const handlePrev = () => {
        navigate(`/wizard/tasks`)
    }

    const nextEnabled = wizardProcess.teams.filter(team => team.selected).length && wizardProcess.tools.filter(tool => tool.selected).length

    return (
        <>
            <WizardFrame next={handleNext} prev={handlePrev} nextDisabled={!nextEnabled}>
                <h2>Let's learn more about your process: {wizardProcess.name}</h2>

                <div className="d-flex justify-content-start mt-5">
                    <div className="align-content-center me-3">
                        <strong>Which teams are involved in the process?</strong>
                    </div>
                </div>
                <WizardItemSelect itemType="teams"></WizardItemSelect>

                <div className="d-flex justify-content-start mt-5">
                    <div className="align-content-center me-3">
                        <strong>Which systems are used to manage the process?</strong>
                    </div>
                </div>
                <WizardItemSelect itemType="tools"></WizardItemSelect>
            </WizardFrame>
        </>)
}