import React, {useState} from "react";
import {Button, Col, Form, FormControl, Row} from "react-bootstrap";
import {setupOrganization} from "../api";
import {useNavigate} from "react-router";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {organizationUuidState, userState} from "../store";
import {FullContainer} from "../components/FullContainer";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";

export function SetupOrganizationView() {

    const [organizationName, setOrganizationName] = useState('')
    const [invites, setInvites] = useState([{email: ''}]);
    const navigate = useNavigate()
    const user = useRecoilValue(userState)
    const setOrganizationUuid = useSetRecoilState(organizationUuidState)
    const firstOrganization = !user?.organizations?.length

    const handleAddInvite = () => {
        setInvites([...invites, {email: ''}]);
    };

    const handleEmailChange = (index: number, event: any) => {
        const newInvites = [...invites];
        newInvites[index].email = event.target.value;
        setInvites(newInvites);
    };

    const handleRemoveInvite = (index: number) => {
        const newInvites = invites.filter((_, i) => i !== index);
        setInvites(newInvites);
    };

    const onFormSubmit = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const invitedEmails = invites.map(invite => invite.email.trim()).filter(email => email.length > 0);
        setupOrganization({organizationName, invitedEmails})
            .then(resp => resp.uuid)
            .then(organizationUuid => setOrganizationUuid(organizationUuid))
            .then(_ => navigate("/home"))
            .catch(error => console.error(error))
    }

    return (
        <FullContainer>
            <h2>{firstOrganization ? 'Welcome to ProcessPlot!' : 'Set up an organization'}</h2>
            <div className="mt-2">
                    <span>
                        {firstOrganization ?
                            'Before you start building your first process please set up your account.'
                            : ''}
                        </span>
            </div>
            <Form className="mt-5" onSubmit={onFormSubmit}>
                <Row>
                    <Col md={4}>
                        <strong>What is the name of your organization?</strong>
                    </Col>
                    <Col md={5}>
                        <FormControl
                            value={organizationName}
                            type="text"
                            maxLength={80}
                            minLength={5}
                            required={true}
                            onChange={e => setOrganizationName(e.currentTarget.value)}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={4}>
                        <strong>Invite additional team members to join your organization</strong>
                    </Col>
                    <Col md={5}>
                        {invites.map((invite, index) => (
                            <Row key={index} className="mb-3">
                                <Col>
                                    <Form.Control
                                        type="email"
                                        value={invite.email}
                                        onChange={(event) => handleEmailChange(index, event)}
                                        placeholder={`example@example.com`}
                                    />
                                </Col>
                                <Col xs="auto" className="d-flex justify-content-center align-items-center"
                                     style={{cursor: "pointer"}}>
                                    <XMarkIcon className="icon-style" onClick={() => handleRemoveInvite(index)}/>
                                </Col>
                            </Row>
                        ))}
                        <Button variant="outline-secondary" onClick={handleAddInvite}>Add email</Button>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md={9}></Col>
                    <Col md={3}>
                        <Button
                            type="submit"
                            onSubmit={onFormSubmit}
                        >Take me to the dashboard</Button>
                    </Col>
                </Row>
            </Form>
        </FullContainer>
    );
}