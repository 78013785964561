import React, {useEffect} from 'react';
import {Outlet} from "react-router";
import {TopNavBar} from "./components/TopNavBar";
import {AvoidRouter} from "./routes/process-view/graph/LibAvoidRouter";

function App() {

    useEffect(() => {
        AvoidRouter.load().then(_ => console.log("avoidlib-js loaded"))
    }, []);

    return (<>
            <TopNavBar/>
            <Outlet/>
        </>
    )
}

export default App;
