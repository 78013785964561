import React from "react"

interface FullContainerProps {
    children: any
}

export function FullContainer(props: FullContainerProps) {

    return (<>
        <div className="d-flex flex-column m-5">
                {props.children}
        </div></>)
}