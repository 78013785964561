import React, {useEffect, useState} from "react";
import {getProcess, saveProcess} from "../../api";
import {useParams} from "react-router";
import {Process} from "../../interfaces";
import {Button, ButtonGroup, Col, Form, Row} from "react-bootstrap";
import {FullContainer} from "../../components/FullContainer";
import {ProcessGraph} from "./graph/ProcessGraph";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {organizationUuidState, processState, processViewEditModeState,} from "../../store";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import {DocumentIcon, ShareIcon, TableCellsIcon, ViewColumnsIcon} from "@heroicons/react/24/solid";
import TaskList from "./TaskList";
import {findRelatedTasks} from "./graph/ProcessUtils";
import {ArrowPathIcon} from "@heroicons/react/16/solid";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";


export default function ProcessView() {
    const params = useParams()
    const [process, setProcess] = useRecoilState<Process>(processState)
    const resetProcess = useResetRecoilState(processState)
    const organizationUuid = useRecoilValue(organizationUuidState)
    const [viewMode, setViewMode] = useState<'graph' | 'table' | 'both'>('both')
    const [editMode, setEditMode] = useRecoilState<boolean>(processViewEditModeState)
    const [saving, setSaving] = useState(false)
    const [triggerPdfExport, setTriggerPdfExport] = useState<((() => void) | null)>(null)

    useEffect(() => {
        if (params.uuid) {
            getProcess(params.uuid)
                .then(response => {
                    setProcess(response);
                })
                .then(_ => setViewMode('both'))
                .catch(error => console.error(error))
        }
    }, [params.uuid, organizationUuid, setProcess]);

    useEffect(() => {
        return () => {
            resetProcess()
        }
    }, [resetProcess]);

    const handleProcessNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        setProcess(prev => ({...prev, name: event.currentTarget.value!}))
    }

    const graphHideStyle = {
        width: viewMode === 'table' ? '0px' : undefined,
        height: viewMode === 'table' ? '0px' : undefined
    }

    const handleProcessSave = () => {
        setSaving(true);
        saveProcess(process)
            .then(_ => setProcess(prev => ({...prev})))
            .then(_ => {
                const interval = setInterval(() => {
                    setSaving(false)
                    clearInterval(interval)
            }, 1000)})
            .catch(error => console.error(error))
    }

    const handleExportTable = () => {
        const doc = new jsPDF();

        const columns = ['Nr', 'Task', 'Optional', 'Team', 'Tool'];
        const rows: any[] = [];

        process.process.tasks.forEach((task, index) => {
            rows.push([index + 1, task.name, task.optional ? 'yes' : 'no', task.team.name, task.tool.name])
            if (task.description) {
                rows.push([{
                    content: task.description,
                    colSpan: 5,
                    styles: {halign:'left'}
                }, '', '', '', ''])
            }
            const {dependsOn, dependedBy} = findRelatedTasks(task.uuid, process.process.tasks, process.process.links)
            if (dependsOn.length > 0) rows.push([{
                content: "After: " + dependsOn.map(task => task.name).join(", "),
                colSpan: 5,
                styles: {halign:'left'}
            }, '', '', '', ''])
            if (dependedBy.length > 0) rows.push([{
                content: "Before: " + dependedBy.map(task => task.name).join(", "),
                colSpan: 5,
                styles: {halign:'left'}
            }, '', '', '', ''])
        })

        autoTable(doc, {
            head: [columns],
            body: rows,
            startY: 20,
            theme: "striped",
            styles: { fontSize: 10, cellPadding: 3 },
            columnStyles: { 2: { halign: "right" } },
        });

        doc.save("process.pdf");
    }

    return (<FullContainer>
        <Row className="mb-4 ">
            <div className="d-flex flex-row">
                <div className="w-100">
                    <h3 className="d-flex">
                        <Form.Control as="input"
                                      className="plain-input h3"
                                      disabled={!editMode}
                                      value={process?.name}
                                      plaintext
                                      style={{textDecoration: 'ThreeDHighlight'}}
                                      placeholder={`Set task name ...`}
                                      onChange={handleProcessNameChange}/>
                    </h3>
                </div>

                {editMode && <div className="ms-3">
                    <Button size="sm" variant="outline-secondary" style={{minWidth: '60px'}}
                            onClick={() => handleProcessSave()}><div className="d-flex flex-row justify-content-center align-items-center"><span>Save</span> {saving  && <ArrowPathIcon className="ms-1 icon-style-sm rotating"/>}</div></Button>
                </div>}
                <div className="ms-3">
                    {/* TODO: on view reload state from backend */}
                    <Button size="sm" variant={editMode ? 'danger' : 'secondary'} style={{width: '60px'}}
                            onClick={() => setEditMode(!editMode)}>{editMode ? 'Edit' : 'View'}</Button>
                </div>
                <div className="ms-3">
                    <Button size="sm"
                            style={{height: 30}}
                            className="d-flex align-items-center"
                            variant="secondary"
                            onClick={() => triggerPdfExport!()}><DocumentIcon
                        className={"icon-style"}/></Button>
                </div>
                <div className="ms-3">
                    <Button size="sm"
                            style={{height: 30}}
                            className="d-flex align-items-center"
                            variant="secondary"
                            onClick={() => handleExportTable()}><TableCellsIcon
                        className={"icon-style"}/></Button>
                </div>
                <div className="ms-3">
                    <ButtonGroup>
                        <Button
                            className="d-flex align-items-center" style={{height: 30}}
                            size="sm" onClick={() => setViewMode('graph')} active={viewMode === 'graph'}
                            variant="outline-secondary"><ShareIcon className="icon-style"/></Button>
                        <Button
                            className="d-flex align-items-center"
                            size="sm" onClick={() => setViewMode('table')} active={viewMode === 'table'}
                            variant="outline-secondary"><Bars3Icon className="icon-style"/></Button>
                        <Button
                            className="d-flex align-items-center"
                            size="sm" onClick={() => setViewMode('both')} active={viewMode === 'both'}
                            variant="outline-secondary"><ViewColumnsIcon className="icon-style"/></Button>
                    </ButtonGroup>
                </div>
            </div>
        </Row>
        <Row className="d-flex justify-content-center">
            {<Col style={graphHideStyle} md={viewMode === 'both' ? 6 : 12}>
                <ProcessGraph registerTriggerPdfExport={setTriggerPdfExport} hide={viewMode === 'table'}
                              editMode={editMode}></ProcessGraph>
            </Col>}
            {viewMode !== 'graph' && <Col md={viewMode === 'both' ? 6 : 8}>
                <TaskList
                    wideMode={viewMode === 'table'}
                    editMode={editMode}
                    templateUuid={process.templateUuid}
                    organizationUuid={organizationUuid}/>
            </Col>}
        </Row>
    </FullContainer>)
}
