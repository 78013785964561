import {Form, FormControl} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import WizardItemSelect from "../WizardItemSelect";
import {createWizardProcess, getTemplates, saveTemplate, updateWizardProcess} from "../../../api";
import {useRecoilState} from "recoil";
import {wizardProcessState} from "../../../store";
import {useNavigate} from "react-router";
import {ProcessTemplate} from "../../../interfaces";
import WizardFrame from "../WizardFrame";
import ItemAddModal from "../ItemAddModal";

export default function WizardTasks() {
    const [templates, setTemplates] = useState<ProcessTemplate[]>([])
    const [wizardProcess, setWizardProcess] = useRecoilState(wizardProcessState)
    const allowNavigateNext = wizardProcess.tasks?.filter(task => task.selected).length > 0 && wizardProcess.name?.length;
    const navigate = useNavigate();

    useEffect(() => {
        getTemplates().then(data => {
                setTemplates(data)
                if(!wizardProcess?.templateUuid){
                    const template = data[0];
                    setWizardProcess(prev => ({
                        ...prev,
                        templateUuid: template.uuid,
                        tasks: template.tasks,
                        tools: template.tools,
                        teams: template.teams
                    }))
                }

            })
            .catch(error => console.error(error))
    }, [setWizardProcess, wizardProcess.templateUuid]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWizardProcess(prev => ({
            ...prev,
            name: event.currentTarget.value
        }))

    }
    const handleSelectTemplateUuid = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const templateUuid = event.target.value as string;
        const template = templates.find(template => template.uuid === templateUuid)
        setWizardProcess(prev => ({
            ...prev,
            templateUuid: templateUuid,
            tasks: template?.tasks || [],
            tools: template?.tools || [],
            teams: template?.teams || []
        }))

    }
    const handleClickNext = () => {
        if (wizardProcess.uuid) {
            updateWizardProcess(wizardProcess)
                .then(_ => navigate(`/wizard/teams-and-tools`))
                .catch(error => console.error(error))
        } else {
            createWizardProcess(wizardProcess)
                .then(result => setWizardProcess(prev => ({...prev, uuid: result.wizardProcessUuid})))
                .then(_ => navigate(`/wizard/teams-and-tools`))
                .catch(error => console.error(error))
        }
    }

    const handleAddTemplate = (name: string) => {
        saveTemplate(name).then(resp => {
                const newTemplate = {uuid: resp.uuid, name, tasks: [], tools: [], teams: []}
                setTemplates(prev => [...prev, newTemplate])
                setWizardProcess(prev => ({
                    ...prev,
                    templateUuid: resp.uuid,
                    tasks: [],
                    tools: [],
                    teams: []
                }))
            }).catch(error => console.error(error))
    }

    return (
        <WizardFrame next={handleClickNext} nextDisabled={!allowNavigateNext}>
            <h2>Let's get started ...</h2>

            <div className="d-flex justify-content-start mt-5">
                <div className="align-content-center me-3">
                    <strong>What is the name of your process?</strong>
                </div>
                <div className="flex-grow-1 ms-3">
                    <FormControl
                        value={wizardProcess.name}
                        type="text"
                        maxLength={100}
                        onChange={handleNameChange}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-start mt-5">
                <div className="align-content-center me-3">
                    <strong>What type of process are you building?</strong>
                </div>
                <div className="d-flex flex-grow-1 ms-3">
                    <Form.Select className="me-3" defaultValue={wizardProcess.templateUuid}
                                 value={wizardProcess.templateUuid}
                                 onChange={handleSelectTemplateUuid}>
                        {templates.map((item, index) => (
                            <option key={`template_${index}`} value={item.uuid}>{item.name}</option>
                        ))}
                    </Form.Select>
                    <ItemAddModal itemType="template" handleNewItem={handleAddTemplate}></ItemAddModal>
                </div>
            </div>

            <div className="d-flex justify-content-start mt-5">
                <div className="align-content-center me-3">
                    <strong>Which of these tasks apply to your process?</strong>
                </div>
            </div>
            <WizardItemSelect itemType="tasks"></WizardItemSelect>
            <div className="mb-5"></div>
        </WizardFrame>
    )
}