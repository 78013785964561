/**
 * Formats date string to human friendly date, with dates falling to today and yesterday replaced by text
 */
function formatDate(dateStr: string) {
    const dateToCheck = new Date(dateStr);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    const localDateToCheck = new Date(dateToCheck.getTime() - (dateToCheck.getTimezoneOffset() * 60000));
    localDateToCheck.setHours(0, 0, 0, 0);
    if (localDateToCheck.getTime() === today.getTime()) {
        return 'Today';
    } else if (localDateToCheck.getTime() === yesterday.getTime()) {
        return 'Yesterday';
    } else {
        return localDateToCheck.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }
}

/**
 * Creates initials from name, splitting either by space or dash
 */
function getInitials(fullName: string) {
    const nameParts = fullName.split(/[\s-]+/).filter(part => part.trim() !== '');
    if (nameParts.length === 1 && nameParts[0].length > 1) {
        return nameParts[0].substring(0, 2)
    }
    return nameParts.map(part => part.charAt(0).toUpperCase()).join('');
}

const conditionalWrap = (condition: boolean, wrapper: any, children: any) =>
    condition ? wrapper(children) : children;

function sanitize<T>(data: any, keys: (keyof T)[]): T | undefined {
    if (!data) {
        return undefined;
    }
    const sanitizedData: Partial<T> = {};

    keys.forEach((key) => {
        if (key in data) sanitizedData[key] = data[key];
    });

    return sanitizedData as T;
}

export {formatDate, getInitials, conditionalWrap, sanitize}
