import React, {useEffect, useState} from "react";
import {deleteWizardProcess, getProcessList, getWizardProcess, getWizardProcessList} from "../api";
import {Process, WizardProcess} from "../interfaces";
import {Badge, Card, Col, Container, Row} from "react-bootstrap";
import {formatDate, getInitials} from "../utils";
import {useNavigate} from "react-router";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {organizationUuidState, wizardProcessState} from "../store";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";

export default function ProcessListView() {

    const [wizardProcessList, setWizardProcessList] = useState<WizardProcess[]>([])
    const [processList, setProcessList] = useState<Process[]>([])
    const setWizardProcess = useSetRecoilState<WizardProcess>(wizardProcessState)
    const organizationUuid = useRecoilValue(organizationUuidState)
    const navigate = useNavigate();

    useEffect(() => {
        getWizardProcessList()
            .then(result => setWizardProcessList(result || []))
            .catch(error => console.error(error))

        getProcessList()
            .then(result => setProcessList(result || []))
            .catch(error => console.error(error))
    }, [organizationUuid]);

    const handleSelect = (process: WizardProcess) => {
        if (process.status === 'PENDING') {
            getWizardProcess(process.uuid).then(data => {
                setWizardProcess(data)
                navigate(`/wizard/tasks`)
            })
        } else {
            navigate(`/process/${process.uuid}`);
        }
    }

    const handleDelete = (process: WizardProcess) => {
        deleteWizardProcess(process.uuid).then(_ => {
            setWizardProcessList(prev => [...prev!.filter(proc => proc.uuid !== process.uuid)])
        })
    }

    return (<>
        <Container className="d-flex flex-column">
            <Container className="m-3 mt-4">
                <h2>Open a previously created process</h2>
                <div className="pt-3">
                    {[...processList,...wizardProcessList]?.map(process =>
                        <ProcessListCard key={process.uuid} process={process} onDelete={handleDelete}
                                         onSelect={handleSelect}></ProcessListCard>
                    )}
                </div>
            </Container>
        </Container>
    </>)
}

interface ProcessListCardProps {
    process: WizardProcess | Process,
    onSelect: (process: WizardProcess | Process) => void,
    onDelete: (process: WizardProcess | Process) => void
}

const ProcessListCard = (props: ProcessListCardProps) => {
    const formattedDate = formatDate(props.process.updatedAt!)
    const initials = getInitials(props.process.updatedBy!)

    const badgeStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
    };

    const getStatus = (process: WizardProcess | Process) => {
        if(!process.hasOwnProperty('status')) {
            return 'COMPLETE';
        }
        let status = (process as any).status;
        switch (status) {
            case 'PENDING':
                return 'DRAFT'
            default:
                return status
        }
    }

    function onClick() {
        props.onSelect(props.process)
    }

    function onDeleteClick(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
        props.onDelete(props.process)
    }

    return (
        <Row key={props.process.uuid}>
            <Card className="process-list-hover-card" onClick={onClick}>
                <Card.Body>
                    <Row>
                        <Col md={6} className="d-flex align-items-center">
                            <Card.Text className="text-start">
                                {props.process.name}
                            </Card.Text>
                        </Col>
                        <Col md={1} className="d-flex align-items-center">
                            <Card.Text>
                                <Badge style={badgeStyle}>{initials}</Badge>
                            </Card.Text>
                        </Col>
                        <Col md={3} className="d-flex align-items-center">
                            <Card.Text>
                                {formattedDate}
                            </Card.Text>
                        </Col>
                        <Col md={1} className="d-flex align-items-center">
                            <Card.Text>
                                <Badge>{getStatus(props.process)}</Badge>
                            </Card.Text>
                        </Col>
                        <Col md={1} onClick={onDeleteClick} className="d-flex align-items-center">
                            <XMarkIcon className="icon-style ms-auto"/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>)
}